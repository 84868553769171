<template>
  <div class="home-container">
    <!-- 中间 -->
    <div class="content-wrapper">
      <div class="info-area">
        <h2>刘培硕的个人网站</h2>
        <div>Liu peishuo's Personal Website</div>
      </div>
      <div class="list-area">
        <div
          class="list-item"
          v-for="item in listItem"
          :key="item.id"
          @click="listItemClick(item.pathUrl)"
        >
          <span class="title">{{ item.title }}</span>
          <div class="outline">
            <div class="outline-area">
              <div class="outline-title">
                <span class="imgIcon">
                  <img :src="item.imgIcon" alt="" srcset="" />
                </span>
                <Divider type="vertical" />
                <span class="type-name">{{ item.title }}</span>
              </div>
              <div class="outline-txt">
                {{ item.outlineTxt }}
              </div>
              <div class="outline-btn"><span>详情</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 尾部 -->
    <div class="footer-wrapper">
      <span>www.lspzy.com All right reserved.</span>
      <span>
        <a href="https://beian.miit.gov.cn" target="_blank">
          苏ICP备19037192号-1
        </a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      listItem: [
        {
          id: 1,
          title: '个人博客',
          imgIcon: require('../../assets/home/blogNews.png'),
          pathUrl: '',
          outlineTxt: '抱歉，暂时未有描述'
        },
        {
          id: 2,
          title: '案例中心',
          imgIcon: require('../../assets/home/caseCenter.png'),
          pathUrl: '',
          outlineTxt: '抱歉，暂时未有描述'
        },
        {
          id: 3,
          title: '学习记录',
          imgIcon: require('../../assets/home/studyHistory.png'),
          pathUrl: '',
          outlineTxt: '抱歉，暂时未有描述'
        },
        {
          id: 4,
          title: '我的资料',
          imgIcon: require('../../assets/home/myInfo.png'),
          pathUrl: '',
          outlineTxt: '抱歉，暂时未有描述'
        }
      ]
    }
  },
  methods: {
    listItemClick (pathUrl) {
      pathUrl ? this.$router.push({
        path: pathUrl,
        params: {
          name: pathUrl
        }
      }) : alert('抱歉，此模块暂未开通')
    }
  }
}
</script>

<style lang="scss" scoped>
.home-container {
  position: absolute;
  background-color: #2d6187;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;

  .content-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);

    .info-area {
      position: relative;
      text-align: center;
      margin-bottom: 30px;

      h2 {
        font-size: 22px;
        color: #fff;
        margin: 10px 0;
        white-space: nowrap;
      }

      div {
        font-size: 18px;
        color: #fff;
        margin: 5px 0;
        white-space: nowrap;
      }
    }

    .list-area {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;

      .list-item {
        position: relative;
        width: 120px;
        height: 350px;
        margin: 0 20px;
        border-radius: 5px;
        background-color: #4289bb;
        cursor: pointer;
        transition: width 600ms ease 0s;
        -webkit-transition: width 600ms ease 0s;
        opacity: 0.5;
        overflow: hidden;

        span.title {
          position: relative;
          color: #fff;
          font-size: 22px;
          font-weight: bold;
          -webkit-writing-mode: vertical-rl;
          writing-mode: vertical-rl;
          white-space: nowrap;
          letter-spacing: 10px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
        }

        div.outline {
          display: none;

          .outline-area {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            overflow: hidden;

            .outline-title {
              position: absolute;
              color: #fff;
              font-weight: bold;
              left: 20px;
              top: 20px;

              .imgIcon {
                position: relative;
                display: inline-block;
                vertical-align: middle;

                img {
                  position: relative;
                  width: 25px;
                  height: 25px;
                  border: none;
                }
              }

              .type-name {
                position: relative;
                font-size: 18px;
                white-space: nowrap;
                display: inline-block;
                vertical-align: middle;
              }
            }

            .outline-txt {
              position: relative;
              margin: 60px 55px;
              width: 380px;
              color: #fff;
              font-size: 13px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 13;
              overflow: hidden;
              line-height: 18px;
            }

            .outline-btn {
              position: absolute;
              color: #fff;
              font-size: 14px;
              font-weight: bold;
              right: 30px;
              bottom: 25px;

              span {
                border-bottom: 0.5px solid #fff;
                padding-bottom: 2px;
                white-space: nowrap;
              }
            }
          }
        }

        &:hover {
          width: 500px;
          opacity: 1;

          div.outline {
            display: block;
          }

          span.title {
            display: none;
          }
        }
      }
    }
  }

  .footer-wrapper {
    position: absolute;
    width: 60%;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
    text-align: center;

    span {
      margin: 0 3px;
      font-size: 12px;
      color: #fff;
      line-height: 30px;

      a {
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .list-area {
    flex-direction: column !important;

    .list-item {
      width: 300px !important;
      height: 50px !important;
      margin: 10px 0 !important;
      opacity: 1 !important;

      span.title {
        position: absolute !important;
        -webkit-writing-mode: unset !important;
        writing-mode: unset !important;
        font-size: 18px !important;
        line-height: 50px;
      }

      &:hover {
        span.title {
          display: block !important;
        }

        div.outline {
          display: none !important;
        }
      }
    }
  }

  .footer-wrapper {
    span {
      display: block;
      line-height: 18px !important;
    }
  }
}
</style>
